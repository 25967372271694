import React, { Component } from 'react'
import { TransitionState } from 'gatsby-plugin-transition-link'
import posed from 'react-pose'
import styled from 'styled-components'
import sizes from '../_vars/sizes'
import colors from '../_vars/colors'

import Colophon from '../components/colophon'
import Header from '../components/header--full'
import SEO from '../components/seo'

const Wrapper = styled.div`
  display: grid;
  min-height: 100vh;
  width: 100%;
  margin: 0;
`

const StyledPage = styled.div`
  /* display: grid; */
  /* background-color: #2288ff; */
  background-color: ${colors.accent};
  color: ${colors.text};
  min-height: calc( 100vh - ${sizes.navBarHeight} );
  margin-top: ${sizes.navBarHeight};
  padding: ${sizes.paddingMed} 1rem;

  a {
    /* color: ${colors.text}; */
    color: ${colors.text};
    transition: 0.25s;

    &:hover {
      color: ${colors.accentColorful};
    }
  }
`

const MainCol = styled.div`
  width: 100%;
  max-width: ${sizes.processTextMax};
  margin: 0 auto;
  padding: 1rem 0;
`

const PosedContainer = posed.div({
  hidden: {
    opacity: 0,
    x: '100%',
  },
  visible: {
    opacity: 1,
    x: '0%',
    transition: { duration: 250 },
  },
})

// const transX = 100

class AboutPage extends Component {
  render() {
    return (
      <Wrapper>
        <Header />
        <TransitionState>
          {({ transitionStatus }) => {
            return (
              <PosedContainer
                pose={
                  ['entering', 'entered'].includes(transitionStatus)
                    ? 'visible'
                    : 'hidden'
                }
              >
                <StyledPage>
                  <MainCol>
                    <SEO
                      title="About"
                      keywords={['typemedia', 'type design', '2018', 'KABK']}
                    />
                    <h1>TypeMedia 2018</h1>
                    <div>
                      <p>
                        KABK{' '}
                        <a
                          href="http://typemedia.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          TypeMedia
                        </a>{' '}
                        is a masters program for type design and technology at
                        the Royal Academy of Arts in The Hague, NL.
                      </p>
                      <p>
                        It is a 10-month program that dives deep into a wide
                        range of theories in type design, combining approaches
                        that range from classical to cutting-edge. The first
                        semester includes many classes and several concurrent
                        projects, and the second semester focuses on a single
                        thesis project from each student.
                      </p>
                      <p>
                        The class of 2018 was made up of students from Croatia,
                        Denmark, France, Germany, India, Korea, Mexico, Poland,
                        Spain, and the United States.
                      </p>
                      <p>
                        This site shows the outcomes and process of each
                        student's thesis project, created in February–June of
                        2018.
                      </p>
                      <hr />
                      <Colophon />
                    </div>
                  </MainCol>
                </StyledPage>
              </PosedContainer>
            )
          }}
        </TransitionState>
      </Wrapper>
    )
  }
}

export default AboutPage
